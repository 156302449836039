






















import Vue from "vue";

export default Vue.component("SHOrderCardSkeleton", {
  components: {
    QSkeleton: () => import("../elements/QSkeleton.vue"),
  },
});
